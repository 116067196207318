@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@400;700&display=swap');
@font-face {
  font-family: 'DIN';
  src: url('./fonts/din-regular.woff2') format('woff2'),
    url('./fonts/din-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DIN';
  src: url('./fonts/din-medium.woff2') format('woff2'),
    url('./fonts/din-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DIN';
  src: url('./fonts/din-bold.woff2') format('woff2'), url('./fonts/din-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
}
